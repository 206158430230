import styled, { css } from 'styled-components'
import { theme } from '@shared/config'
import { Text as TextImport } from '@shared/ui'
import { DESKTOP_WIDTH } from '../../config'

const MobileContainer = styled.div`
  display: block;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    display: none;
  }

  > :not(:last-child) {
    margin-bottom: ${theme.spacings.xs3}px;
  }
`

const DesktopContainer = styled.div`
  display: none;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    display: block;
  }
`

const Text = styled(TextImport)<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? theme.colors.tealDark : theme.colors.blueDark)};
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      border-left: 4px solid ${theme.colors.tealDark};
      padding-left: ${theme.spacings.xs}px;
    `}
`

export const Styled = {
  MobileContainer,
  DesktopContainer,
  Text,
}
