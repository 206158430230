import styled, { css } from 'styled-components'
import { theme } from '@shared/config'
import { Text as TextImport } from '@shared/ui'
import { TInfoTitle } from '../../types'
import { DESKTOP_WIDTH } from '../../config'

const Container = styled.div`
  > :not(:last-child) {
    margin-bottom: ${theme.spacings.xs3}px;
  }
`

const Text = styled(TextImport)<TInfoTitle>`
  ${({ isDesktopHidden, isMobileHidden }) => css`
    display: ${isMobileHidden ? 'none' : 'block'};

    @media (min-width: ${DESKTOP_WIDTH}px) {
      display: ${isDesktopHidden ? 'none' : 'block'};
    }
  `}
`

export const Styled = {
  Container,
  Text,
}
