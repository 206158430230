import { GetStaticProps } from 'next'
import { TInfoDisclosure } from '@features'
import { getHeaderFooter } from '@entities'
import { REVALIDATE_TIME } from '@shared/config'
import { getCommonRequest } from '@shared/api'
import { CONSUL_EXIBANK } from '@shared/constants'

export const getPageStaticProps: GetStaticProps<TInfoDisclosure> = async () => {
  const [{ data: pageData }, headerFooter] = await Promise.all([
    getCommonRequest<TInfoDisclosure>(`${CONSUL_EXIBANK}aboutBank`, {
      next: { revalidate: REVALIDATE_TIME },
    }),
    getHeaderFooter('/'),
  ])

  if (!pageData) {
    throw new Error(`Consul: Received page data is null`)
  }
  if (headerFooter === null) {
    throw new Error(`Consul: Received header-footer data is null`)
  }

  return {
    props: { ...pageData, headerFooter },
    revalidate: REVALIDATE_TIME,
  }
}
