import { TNullable } from '@shared/types'

// Проверка значения на Record<string, unknown>
export const isObjectRecord = (value: unknown): value is Record<string, unknown> =>
  typeof value === 'object' && value !== null && !Array.isArray(value)

// Проверка значения на Null или undefined
export const isNullable = <Entity>(value: TNullable<Entity>): value is null | undefined =>
  value === null || value === undefined

export const isNumber = (value: unknown): value is number => typeof value === 'number' && Number.isFinite(value)
