export const colors = {
  tealDark: '#036D7B',
  blueDark: '#0A1244',
  grayLight: '#CFD1DF',
  neutral: '#FFFDF7',
  iceberg: '#E0F3F5',
  neutrals: {
    g100: '#000000',
    g300: '#626C77',
    g600: '#E2E5EB',
    g700: '#F2F3F7',
  },
} as const
