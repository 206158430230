import React from 'react'
import styled from 'styled-components'
import { TTable } from '@features/infoDisclosure/types'
import { Text, TTableProps } from '@shared/ui'

const ACCESSOR_PART = 'col'

const InlineText = styled(Text)`
  display: inline;
  font-weight: inherit;
  font-size: inherit;
`

export const generateTableProps = ({ columns, data }: TTable): Pick<TTableProps, 'columns' | 'data'> => ({
  columns: columns.map(({ header, ...data }, i) => ({
    bodyProps: {
      fontWeight: i ? undefined : 'bold',
    },
    headerProps: {
      fontWeight: i ? undefined : 'bold',
    },
    ...data,
    Header: header,
    accessor: `${ACCESSOR_PART}${i}`,
  })),
  data: data.map((arrContent) =>
    arrContent.reduce(
      (result, content, i) => ({
        ...result,
        [`${ACCESSOR_PART}${i}`]: <InlineText>{content}</InlineText>,
      }),
      {}
    )
  ),
})
