import React, { Fragment } from 'react'
import { List } from '@entities'
import { Table, Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TInfoItem, TInfoTitle } from '../../types'
import { generateTableProps, isTable, isList } from './lib'
import { Styled } from './styled'

export const Info = ({ title, data, titleProps }: { title?: string; data?: TInfoItem[]; titleProps?: TInfoTitle }) => {
  if (!title && !data?.length) {
    return null
  }

  return (
    <Styled.Container>
      {title && (
        <Styled.Text
          fontSizeMob={theme.fonts.fontSizes.sm4}
          fontWeightMob={theme.fonts.fontWeights.bold}
          {...titleProps}
        >
          {title}
        </Styled.Text>
      )}
      {data?.map((part, index) => {
        if (typeof part === 'string') {
          return <Text key={part.substring(0, 100)}>{part}</Text>
        }
        if (isTable(part)) {
          const { columns, data, title, ...restTable } = part

          return (
            <Fragment key={index}>
              {title && (
                <Text
                  textAlign="center"
                  fontSizeMob={theme.fonts.fontSizes.sm1}
                  fontWeightMob={theme.fonts.fontWeights.bold}
                >
                  {title}
                </Text>
              )}
              <Table {...generateTableProps({ columns, data })} noDividers {...restTable} />
            </Fragment>
          )
        }

        if (isList(part)) {
          return <List {...part} key={index} />
        }

        return null
      })}
    </Styled.Container>
  )
}
