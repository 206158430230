import styled, { css } from 'styled-components'
import { theme } from '@shared/config'
import { getCssTemplateValue } from '@shared/lib'
import { TColumnStyle, TCellStyle as TCellStyleImport } from './types'

type TCellStyle = Pick<TColumnStyle, 'textAlign' | 'rightGap'> &
  TCellStyleImport & {
    noDividers?: boolean
    horizPadding?: number
    width?: string | number
    hasAdaptive?: boolean
  }

const cellsPaddigs = css<TCellStyle>`
  ${({ horizPadding = theme.spacings.xs }) => `
    &:first-child {
    padding-left: ${horizPadding}px;
  }

  &:last-child {
    padding-right: ${horizPadding}px;
  }`}
`

const withDividerPaddings = css`
  padding-top: ${theme.spacings.md}px;
  padding-bottom: ${theme.spacings.md}px;
`

const noDividerPaddings = css`
  padding-top: 10px;
  padding-bottom: 10px;
`

const Table = styled.table`
  box-sizing: content-box;
`

const Tr = styled.tr<{ hasAdaptive?: boolean }>`
  display: ${({ hasAdaptive }) => (hasAdaptive ? 'block' : 'table-row')};

  @media (min-width: ${theme.breakpoints.md}px) {
    display: table-row;
  }
`

const thDesktop = css<TCellStyle>`
  display: table-cell;
  background-color: ${theme.colors.neutrals.g700};
  padding-top: ${theme.spacings.xs}px;
  padding-bottom: ${theme.spacings.xs}px;
  ${cellsPaddigs}

  &:not(:last-child) {
    padding-right: ${({ rightGap = theme.spacings.sm }) => getCssTemplateValue(rightGap)};
  }
`

const Th = styled.th<TCellStyle>`
  display: block;
  color: ${({ textColor = theme.colors.neutrals.g300 }) => textColor};
  font-size: ${theme.fonts.fontSizes.xs2}px;
  line-height: ${theme.fonts.lineHeights.xs2}px;
  text-align: ${({ textAlign = 'start' }) => textAlign};
  font-weight: ${({ fontWeight = 'regular' }) => theme.fonts.fontWeights[fontWeight]};
  width: ${({ width }) => getCssTemplateValue(width)};
  ${({ hasAdaptive, noDividers }) =>
    hasAdaptive
      ? css`
          padding-top: ${noDividers ? theme.spacings.xs2 : theme.spacings.sm}px;
          padding-bottom: ${theme.spacings.xs4}px;

          @media (min-width: ${theme.breakpoints.md}px) {
            ${thDesktop}
          }
        `
      : thDesktop}
`

const tdhDesktop = css<TCellStyle>`
  display: table-cell;

  ${({ noDividers }) =>
    noDividers
      ? noDividerPaddings
      : css`
          ${withDividerPaddings}
          box-shadow: inset 0px -1px 0px ${theme.colors.neutrals.g600};
        `}
  &:not(:last-child) {
    padding-right: ${({ rightGap = theme.spacings.sm }) => getCssTemplateValue(rightGap)};
  }
  ${cellsPaddigs}
`

const Td = styled.td<TCellStyle>`
  display: block;
  color: ${({ textColor = theme.colors.neutrals.g100 }) => textColor};
  font-size: ${theme.fonts.fontSizes.sm1}px;
  line-height: ${theme.fonts.lineHeights.sm1}px;
  text-align: ${({ textAlign = 'start' }) => textAlign};
  font-weight: ${({ fontWeight = 'regular' }) => theme.fonts.fontWeights[fontWeight]};
  width: ${({ width }) => getCssTemplateValue(width)};

  ${({ hasAdaptive, noDividers }) =>
    hasAdaptive
      ? css`
          padding-bottom: ${noDividers ? theme.spacings.xs2 : theme.spacings.sm}px;

          @media (min-width: ${theme.breakpoints.md}px) {
            ${tdhDesktop}
          }
        `
      : tdhDesktop}
`

export const Styled = {
  Table,
  Th,
  Tr,
  Td,
}
