import React, { FC, useCallback } from 'react'
import { Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TInfoDisclosure } from '../types'
import { DocumentGroup } from './DocumentGroup'
import { useActiveGroup } from '../lib/useActiveGroup'
import { Info } from './Info'
import { DocumentListDesktop } from './DocumentListDesktop'
import { Styled } from './styled'

export const InfoDisclosure: FC<TInfoDisclosure> = ({ title, docGroups, titleLoadingMore = 'Загрузить ещё' }) => {
  const [active, setActive] = useActiveGroup({ defaultActive: docGroups?.[0].alias })

  const { info, documents, titleInfo, titleInfoProps } = docGroups.find(({ alias }) => alias === active)

  const handleClickGroup = useCallback(
    (alias: string) => {
      setActive(alias)
    },
    [setActive]
  )

  return (
    <>
      <Text {...theme.header} marginBottomMob={theme.spacings.md2}>
        {title}
      </Text>
      <Styled.Container>
        <Styled.LeftContainer>
          {docGroups.map(({ alias, ...restData }) => (
            <DocumentGroup
              alias={alias}
              key={alias}
              isActive={alias === active}
              onClick={() => handleClickGroup(alias)}
              titleLoadingMore={titleLoadingMore}
              {...restData}
            />
          ))}
        </Styled.LeftContainer>
        <Styled.RightContainer>
          <Info data={info} title={titleInfo} titleProps={titleInfoProps} />
          {!!documents && <DocumentListDesktop documents={documents} titleLoadingMore={titleLoadingMore} />}
        </Styled.RightContainer>
      </Styled.Container>
    </>
  )
}
