import styled, { css } from 'styled-components'
import { theme } from '@shared/config'
import { Text as TextImport } from '@shared/ui'
import { DESKTOP_WIDTH } from '../config'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${theme.spacings.md2}px;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    column-gap: ${theme.spacings.sm}px;
    grid-template-columns: 280px ${theme.documentSize.md * 2}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    padding-left: ${theme.spacings.xl3}px;
  }
`

const Text = styled(TextImport)<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? theme.colors.tealDark : theme.colors.blueDark)};
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      border-left: 4px solid ${theme.colors.tealDark};
      padding-left: ${theme.spacings.xs}px;
    `}
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: ${theme.spacings.xs3}px;
  }

  @media (min-width: ${theme.breakpoints.sm}px) {
    > :not(:last-child) {
      margin-bottom: ${theme.spacings.md2}px;
    }
  }
`

const RightContainer = styled.div`
  display: none;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    display: block;
  }
`

export const Styled = {
  Container,
  Text,
  LeftContainer,
  RightContainer,
}
