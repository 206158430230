import { getCommonRequest } from '@shared/api'
import { CONSUL_HEADER_FOOTER } from '@shared/constants'
import { REVALIDATE_TIME } from '@shared/config'
import { validateFields } from '@shared/lib'
import { trimSlashes } from './trimSlashes'
import {
  TFooterLinks,
  TFooterPhone,
  TLicense,
  TLogin,
  TLogoLink,
  TNavigationLinks,
  THeaderFooter,
  TPagePaths,
} from '../types'
import { isKeyExist } from './isKeyExist'

const FETCH_OPTIONS = {
  next: { revalidate: REVALIDATE_TIME },
}

const EMPTY_RESPONSE = { data: null }

export const getHeaderFooter = async (mainSection?: string): Promise<THeaderFooter> => {
  const { data: pathsByPages } = await getCommonRequest<TPagePaths>(`${CONSUL_HEADER_FOOTER}byPages`)

  validateFields(pathsByPages, ['common'], 'byPages')

  const pathsByPage = Object.keys(pathsByPages).find((key) => trimSlashes(mainSection) === trimSlashes(key)) ?? 'common'

  const pathForKeys = pathsByPages[pathsByPage]

  const [
    { data: promiseFooterLinks },
    { data: promiseFooterPhones },
    { data: promiseHeaderNavigation },
    { data: promiseLicense },
    { data: promiseLogin },
    { data: promiseLogoLink },
  ] = await Promise.all([
    isKeyExist(pathForKeys, 'footerLinks')
      ? getCommonRequest<TFooterLinks>(`${CONSUL_HEADER_FOOTER}${trimSlashes(pathForKeys.footerLinks)}`, FETCH_OPTIONS)
      : Promise.resolve(EMPTY_RESPONSE),
    isKeyExist(pathForKeys, 'footerPhone')
      ? getCommonRequest<TFooterPhone>(`${CONSUL_HEADER_FOOTER}${trimSlashes(pathForKeys.footerPhone)}`, FETCH_OPTIONS)
      : Promise.resolve(EMPTY_RESPONSE),
    isKeyExist(pathForKeys, 'navigationLinks')
      ? getCommonRequest<TNavigationLinks>(
          `${CONSUL_HEADER_FOOTER}${trimSlashes(pathForKeys.navigationLinks)}`,
          FETCH_OPTIONS
        )
      : Promise.resolve(EMPTY_RESPONSE),
    isKeyExist(pathForKeys, 'license')
      ? getCommonRequest<TLicense>(`${CONSUL_HEADER_FOOTER}${trimSlashes(pathForKeys.license)}`, FETCH_OPTIONS)
      : Promise.resolve(EMPTY_RESPONSE),
    isKeyExist(pathForKeys, 'login')
      ? getCommonRequest<TLogin>(`${CONSUL_HEADER_FOOTER}${trimSlashes(pathForKeys.login)}`, FETCH_OPTIONS)
      : Promise.resolve(EMPTY_RESPONSE),
    isKeyExist(pathForKeys, 'logoLink')
      ? getCommonRequest<TLogoLink>(`${CONSUL_HEADER_FOOTER}${trimSlashes(pathForKeys.logoLink)}`, FETCH_OPTIONS)
      : Promise.resolve(EMPTY_RESPONSE),
  ])
  const result: THeaderFooter = {
    navigation: {
      links: null,
    },
    footer: {
      license: null,
    },
  }

  if (promiseFooterLinks) {
    validateFields(promiseFooterLinks, ['links'], 'footerLinks')
    result.footer.links = promiseFooterLinks.links
  }

  if (promiseFooterPhones) {
    validateFields(promiseFooterPhones, ['text'], 'footerPhone')
    result.footer.phone = promiseFooterPhones
  }

  if (promiseHeaderNavigation) {
    validateFields(promiseHeaderNavigation, ['links'], 'navigationLinks')
    result.navigation.links = promiseHeaderNavigation.links
  }

  if (promiseLicense) {
    validateFields(promiseLicense, [], 'license')
    result.footer.license = promiseLicense.license
  }

  if (promiseLogin) {
    validateFields(promiseLogin, ['href', 'title'], 'login')
    result.navigation.login = promiseLogin
  }

  if (promiseLogoLink) {
    validateFields(promiseLogoLink, ['href'], 'logoLink')
    result.navigation.logoLink = promiseLogoLink
  }

  validateFields(result, { navigation: ['links'], footer: ['license'] }, 'headerFooter')

  return result
}
