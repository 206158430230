import React, { FC, ReactNode } from 'react'
import { Footer, Navigation } from '@features'
import { THeaderFooter } from '@entities'
import { Layout } from '@shared/ui'

type Props = THeaderFooter & {
  children: ReactNode | ReactNode[]
  active?: string
}

export const PageLayout: FC<Props> = ({ children, footer, active, navigation }) => (
  <Layout>
    <div>
      <Navigation {...navigation} active={active} />
      {children}
    </div>
    <Footer {...footer} />
  </Layout>
)
