import React, { FC } from 'react'
import Link from 'next/link'
import { Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TFooter } from './types'
import { Styled } from './styled'

export const Footer: FC<TFooter> = ({ phone, links, license }) => {
  const currentYear = new Date().getFullYear()

  return (
    <Styled.Container>
      <Styled.BodyContainer>
        {!!phone && (
          <Text fontSizeMob={theme.fonts.fontSizes.sm3} fontWeightMob={theme.fonts.fontWeights.bold}>
            {phone.text}
          </Text>
        )}
        {Boolean(links?.length) && (
          <Styled.LinkGrid>
            {links.map(({ href, title }) => (
              <Link href={href} key={href}>
                <Text fontSizeMob={theme.fonts.fontSizes.xs2} color={theme.colors.blueDark}>
                  {title}
                </Text>
              </Link>
            ))}
          </Styled.LinkGrid>
        )}
        <Styled.LicenseContainer>
          {license.map((text) => (
            <Text {...theme.license} key={text.substring(0, 100)}>
              {text.replace('{year}', currentYear.toString())}
            </Text>
          ))}
        </Styled.LicenseContainer>
      </Styled.BodyContainer>
    </Styled.Container>
  )
}
