import React, { useMemo, isValidElement } from 'react'
import { useTable } from 'react-table'
import { TTableProps } from './types'
import { Styled } from './styled'

export const Table = ({
  columns: columnsOriginal,
  data,
  horizPadding,
  noDividers,
  hasAdaptive = false,
}: TTableProps) => {
  const defaultColumn = useMemo(
    () => ({
      width: 'auto',
    }),
    []
  )
  const tableInstance = useTable<object>({ columns: columnsOriginal, data, defaultColumn })
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  const isHeadingExists = useMemo(
    () => headerGroups[0].headers.some(({ Header }) => typeof Header === 'string' || isValidElement(Header)),
    [headerGroups]
  )

  return (
    <Styled.Table {...getTableProps()}>
      {isHeadingExists && (
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <Styled.Tr key={idx} {...headerGroup.getHeaderGroupProps()} hasAdaptive={hasAdaptive}>
              {headerGroup.headers.map((column, columnIndex) => (
                <Styled.Th
                  hasAdaptive={hasAdaptive}
                  key={column.id}
                  {...column.getHeaderProps()}
                  {...columnsOriginal[columnIndex].headerProps}
                  textAlign={columnsOriginal[columnIndex].textAlign}
                  rightGap={columnsOriginal[columnIndex].rightGap}
                  horizPadding={horizPadding}
                  width={column.width}
                >
                  {column.render('Header')}
                </Styled.Th>
              ))}
            </Styled.Tr>
          ))}
        </thead>
      )}
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)

          return (
            <Styled.Tr key={row.id} {...row.getRowProps()} hasAdaptive={hasAdaptive}>
              {row.cells.map((cell, columnIndex) => (
                <Styled.Td
                  key={cell.column.id}
                  {...columnsOriginal[columnIndex].bodyProps}
                  textAlign={columnsOriginal[columnIndex].textAlign}
                  rightGap={columnsOriginal[columnIndex].rightGap}
                  width={cell.column.width}
                  noDividers={noDividers}
                  horizPadding={horizPadding}
                  {...cell.getCellProps()}
                  hasAdaptive={hasAdaptive}
                >
                  {cell.render('Cell')}
                </Styled.Td>
              ))}
            </Styled.Tr>
          )
        })}
      </tbody>
    </Styled.Table>
  )
}
