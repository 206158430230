import React, { FC, useMemo, useState } from 'react'
import { MoreButton } from '@entities'
import { Document, Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TDocumentGroup } from './types'
import { Info } from '../Info'
import { Styled } from './styled'

const MAX_DOCUMENTS_COUNT = 5

export const DocumentGroup: FC<TDocumentGroup> = ({
  alias,
  title,
  documents,
  info,
  isActive,
  onClick,
  titleLoadingMore,
  titleInfo,
  titleInfoProps,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isShowMore = useMemo(() => documents?.length > MAX_DOCUMENTS_COUNT, [documents])
  const docs = useMemo(() => (isOpen ? documents : documents?.slice(0, MAX_DOCUMENTS_COUNT)), [isOpen, documents])

  return (
    <>
      <Styled.DesktopContainer>
        <Styled.Text
          onClick={onClick}
          fontWeightMob={theme.fonts.fontWeights.bold}
          fontSizeMob={theme.fonts.fontSizes.sm1}
          isActive={isActive}
        >
          {title}
        </Styled.Text>
      </Styled.DesktopContainer>
      <Styled.MobileContainer id={alias}>
        <Text fontWeightMob={theme.fonts.fontWeights.bold} fontSizeMob={theme.fonts.fontSizes.md}>
          {title}
        </Text>
        <Info data={info} title={titleInfo} titleProps={titleInfoProps} />
        {docs?.map((document, index) => <Document key={index} {...document} />)}
        {isShowMore && !isOpen && <MoreButton onClick={() => setIsOpen(true)}>{titleLoadingMore}</MoreButton>}
      </Styled.MobileContainer>
    </>
  )
}
