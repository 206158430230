import styled from 'styled-components'
import { theme } from '@shared/config'

const MoreContainer = styled.div`
  grid-column: 1 / 3;
`

const Container = styled.div`
  grid-template-columns: 1fr;
  row-gap: ${theme.spacings.sm}px;
  flex-direction: column;
  display: grid;

  @media (min-width: ${theme.breakpoints.sm}px) {
    grid-template-columns: ${theme.documentSize.md}px ${theme.documentSize.md}px;
    column-gap: ${theme.spacings.sm}px;
  }
`
const Item = styled.div`
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: ${theme.spacings.sm}px;
  }
`

export const Styled = {
  MoreContainer,
  Item,
  Container,
}
